<template>
  <div v-if="loaded">
    <div :class="{ 'sticky-adjust': flex_adjust }">
      <div
        class="timer-display"
        :class="{ 'timer-minimal': !format_visible, 'timer-flex': flex_adjust }"
      >
        <span v-if="!format_visible" class="mr-1">Tiempo Restante: </span>
        <span class="mr-1">{{ DisplayHours }}</span> :
        <span class="mx-1">{{ DisplayMinutes }}</span> :
        <span class="ml-1">{{ DisplaySeconds }}</span>
      </div>
      <slot name="flex_content"></slot>
    </div>
    <div class="time-format" v-if="format_visible">
      <span class="mr-1">Horas</span> <span class="ml-1">Minutos</span>
      <span class="ml-2">Segundos</span>
    </div>
    <b-progress height="1em" :max="timer_total">
      <!-- class="custom-progress" (En caso de utilizar color primario guardado en estilos globales)-->
      <b-progress-bar variant="info" :value="timer_current" animated>
      </b-progress-bar>
    </b-progress>
  </div>
</template>

<script>
export default {
  props: {
    start_date: {
      type: String,
    },
    end_date: {
      type: String,
    },
    format_visible: {
      type: Boolean,
      default: true,
    },
    flex_adjust: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    DisplayHours: 0,
    DisplayMinutes: 0,
    DisplaySeconds: 0,
    loaded: false,
    timer_current: 0,
    timer_total: 0,
  }),
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60;
    },
    _hours() {
      return this._minutes * 60;
    },
  },
  mounted() {
    this.showRemainig();
  },
  methods: {
    formatNum: (num) => (num < 10 ? "0" + num : num),
    showRemainig() {
      const timer = setInterval(() => {
        const now = new Date();
        const start = new Date(this.start_date);
        const end = new Date(this.end_date);
        const distance = end.getTime() - now.getTime();
        this.timer_total = end.getTime() - start.getTime();
        this.timer_current = this.timer_total - distance;
        if (distance < 0) {
          clearInterval(timer);
          return;
        }
        const hours = Math.floor(distance / this._hours);
        const minutes = Math.floor((distance % this._hours) / this._minutes);
        const seconds = Math.floor((distance % this._minutes) / this._seconds);
        this.DisplayHours = this.formatNum(hours);
        this.DisplayMinutes = this.formatNum(minutes);
        this.DisplaySeconds = this.formatNum(seconds);
        this.loaded = true;
      }, 0);
    },
  },
};
</script>

<style scoped>
.timer-display {
  font-size: 17pt;
}
.timer-minimal {
  font-size: 12pt !important;
}
.time-format {
  font-size: 10pt;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: -1px;
}
.sticky-adjust {
  display: flex !important;
}
.timer-flex {
  width: 25% !important;
  text-align: left !important;
  margin-left: 2em;
}
@media (max-width: 1000px){
  .timer-display {
    width: 100% !important;
    text-align: center !important;
    margin-left: 0 !important;
  }
  .sticky-adjust{
    display: block !important;
  }
}
/* .custom-progress{
  background: var(--primary-color) !important;
} */
</style>